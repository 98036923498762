import { ViewportScroller } from '@angular/common';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, pairwise } from 'rxjs';

function getBaseRoute(url: string): string {
  return url.split('?')[0];
}

export function handleScrollOnNavigation(): void {
  const router = inject(Router);
  const destroyRef = inject(DestroyRef);
  const viewportScroller = inject(ViewportScroller);

  router.events
    .pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise(),
      takeUntilDestroyed(destroyRef),
    )
    .subscribe(([prevEvent, currEvent]) => {
      if (currEvent.position) {
        // Backward navigation
        viewportScroller.scrollToPosition(currEvent.position);
      } else if (currEvent.anchor) {
        // Anchor navigation
        viewportScroller.scrollToAnchor(currEvent.anchor);
      } else {
        const prevRouterEvent = prevEvent.routerEvent;
        const prevEventUrl =
          prevRouterEvent instanceof NavigationEnd
            ? prevRouterEvent.urlAfterRedirects
            : prevRouterEvent.url;

        const currRouterEvent = currEvent.routerEvent;
        const currEventUrl =
          currRouterEvent instanceof NavigationEnd
            ? currRouterEvent.urlAfterRedirects
            : currRouterEvent.url;

        if (getBaseRoute(prevEventUrl) !== getBaseRoute(currEventUrl)) {
          viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });
}
