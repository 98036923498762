import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Cart } from '@swagger/models';

import { CartActions } from './cart.actions';

interface State {
  addToCartLoading: boolean;
  cart: Cart | undefined;
  cartLoading: boolean;
  error: unknown;
}

const initialState: State = {
  addToCartLoading: false,
  cart: undefined,
  cartLoading: false,
  error: undefined,
};

const reducer = createReducer<State>(
  initialState,
  on(
    CartActions.loadCart,
    (state): State => ({
      ...state,
      cartLoading: true,
    }),
  ),
  on(
    CartActions.loadCartSuccess,
    (state, { cart }): State => ({
      ...state,
      cart,
      cartLoading: false,
    }),
  ),
  on(
    CartActions.loadCartFailure,
    (state, { error }): State => ({
      ...state,
      cartLoading: false,
      error,
    }),
  ),
  on(
    CartActions.addCartItem,
    (state): State => ({
      ...state,
      addToCartLoading: true,
    }),
  ),
  on(
    CartActions.addCartItemSuccess,
    (state, { cart }): State => ({
      ...state,
      addToCartLoading: false,
      cart,
    }),
  ),
  on(
    CartActions.addCartItemFailure,
    (state, { error }): State => ({
      ...state,
      addToCartLoading: false,
      error,
    }),
  ),
  on(
    CartActions.updateCartItem,
    CartActions.createCartOrder,
    CartActions.applyPromocode,
    CartActions.removeCartItem,
    CartActions.releasePromocode,
    (state): State => state,
  ),
  on(
    CartActions.updateCartItemSuccess,
    CartActions.removeCartItemSuccess,
    CartActions.applyPromocodeSuccess,
    CartActions.releasePromocodeSuccess,

    (state, { cart }): State => ({
      ...state,
      cart,
    }),
  ),
  on(
    CartActions.removeCartItemFailure,
    CartActions.updateCartItemFailure,
    CartActions.applyPromocodeFailure,
    CartActions.releasePromocodeFailure,

    (state, { error }): State => ({ ...state, error }),
  ),
);

export const cartFeature = createFeature({
  extraSelectors: ({ selectCart }) => ({
    selectCartLength: createSelector(
      selectCart,
      (cart) => cart?.cart_items?.length ?? 0,
    ),
  }),
  name: 'cart',
  reducer,
});
