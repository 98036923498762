import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartService } from '@swagger/services/cart.service';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { CartActions } from './cart.actions';

@Injectable()
export class CartEffects {
  addCartItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.addCartItem),
      exhaustMap(({ ticketTypeId }) =>
        this.cartService
          .cartAddItemCreate({ ticket_type_id: ticketTypeId })
          .pipe(
            map((cart) => CartActions.addCartItemSuccess({ cart })),
            catchError((error: unknown) =>
              of(
                CartActions.addCartItemFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  applyPromocode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.applyPromocode),
      exhaustMap(({ promocode }) =>
        this.cartService.cartApplyPromocodeCreate({ code: promocode }).pipe(
          map((cart) => CartActions.applyPromocodeSuccess({ cart })),
          catchError((error: unknown) =>
            of(
              CartActions.applyPromocodeFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.loadCart),
      exhaustMap(() =>
        this.cartService.cartMyList().pipe(
          map((cart) => CartActions.loadCartSuccess({ cart })),
          catchError((error: unknown) =>
            of(
              CartActions.loadCartFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  releasePromocode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.releasePromocode),
      exhaustMap(() =>
        this.cartService.cartReleasePromocodeCreate().pipe(
          map((cart) => CartActions.releasePromocodeSuccess({ cart })),
          catchError((error: unknown) =>
            of(
              CartActions.releasePromocodeFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  removeCartItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeCartItem),
      exhaustMap(({ itemId }) =>
        this.cartService.cartRemoveItemDelete(itemId).pipe(
          map((cart) => CartActions.removeCartItemSuccess({ cart })),
          catchError((error: unknown) =>
            of(
              CartActions.removeCartItemFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  updateCartItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateCartItem),
      exhaustMap(({ itemId, quantity, ticketTypeId }) =>
        this.cartService
          .cartUpdateItemPartialUpdate({
            data: { qty: quantity, ticket_type_id: ticketTypeId },
            itemId,
          })
          .pipe(
            map((cart) => CartActions.updateCartItemSuccess({ cart })),
            catchError((error: unknown) =>
              of(
                CartActions.updateCartItemFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private cartService: CartService,
  ) {}
}
