import { NgComponentOutlet, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Type,
  ViewChild,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  TranslocoDirective,
  TranslocoService,
  provideTranslocoScope,
} from '@ngneat/transloco';
import { MenuService, MenuType, ScrollLockService } from '@pirexpo/shared/api';
import { CapitalizePipe } from '@pirexpo/shared/libs';
import { SvgIconComponent } from 'angular-svg-icon';
import { map, skip, tap } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgComponentOutlet,
    TranslocoDirective,
    UpperCasePipe,
    CapitalizePipe,
    SvgIconComponent,
  ],
  providers: [provideTranslocoScope('layout')],
  selector: 'pirexpo-menu',
  standalone: true,
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html',
})
export class MenuComponent {
  private readonly components = {
    cart: () => import('./components/cart').then((c) => c.CartComponent),
    filters: () =>
      import('./components/filter-menu').then((f) => f.FilterMenuComponent),
    menu: () =>
      import('./components/main-menu').then((m) => m.MainMenuComponent),
  };

  activeLang = 'ru';

  readonly component$$ = signal<Type<unknown> | null>(null);

  readonly isFiltersOpened$$ = signal<boolean>(false);

  @ViewChild('menu') menu!: ElementRef<HTMLDivElement>;

  readonly menuIsOpened$$ = toSignal(
    this.menuService.menuState$.pipe(
      skip(1),
      tap(({ isOpened }) => {
        isOpened
          ? this.scrollLock.disableScroll(this.menu.nativeElement)
          : this.scrollLock.enableScroll(this.menu.nativeElement);
      }),
      tap(({ activeState }) => this.loadComponent(activeState)),
      tap(({ activeState }) => {
        const isFiltersOpened = activeState === 'filters';
        this.isFiltersOpened$$.set(isFiltersOpened);
      }),
      map(({ isOpened }) => isOpened),
    ),
  );

  constructor(
    private menuService: MenuService,
    private scrollLock: ScrollLockService,
    private translocoService: TranslocoService,
  ) {}

  private async loadComponent(type: MenuType): Promise<void> {
    if (!type) return;
    const component = await this.components[type]();
    this.component$$.set(component);
  }

  changeLang(): void {
    this.translocoService.getActiveLang() === 'ru'
      ? this.translocoService.setActiveLang('en')
      : this.translocoService.setActiveLang('ru');
    this.activeLang = this.translocoService.getActiveLang();
  }

  close(): void {
    this.menuService.closeMenu();
  }
}
