import {
  APP_BASE_HREF,
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipeConfig,
  registerLocaleData,
} from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  TransferState,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Router,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { provideTransloco } from '@ngneat/transloco';
import { PartialTranslocoConfig } from '@ngneat/transloco/lib/transloco.config';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { RuntimeChecks, provideState, provideStore } from '@ngrx/store';
import {
  StoreDevtoolsOptions,
  provideStoreDevtools,
} from '@ngrx/store-devtools';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { HttpHeadersInterceptor, SvgBrowserLoader } from '@pirexpo/core';
import { CartEffects, cartFeature } from '@pirexpo/entities/cart';
import { environment } from '@pirexpo/env';
import {
  flexLayoutBreakpoints,
  flexLayoutConfigOptions,
  yaMapConfig$,
} from '@pirexpo/shared/app-config';
import * as SentryBrowser from '@sentry/angular-ivy';
import { EventPluginsModule } from '@tinkoff/ng-event-plugins';
import { SvgLoader, provideAngularSvgIcon } from 'angular-svg-icon';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { register as registerSwiper } from 'swiper/element';

import { appRoutes } from './app.routes';
import { ApiConfiguration } from './swagger/api-configuration';
import { TranslocoHttpLoader } from './transloco-loader';

registerSwiper();
registerLocaleData(localeRu);

const DEFAULT_LOCALE = 'ru-RU';
const DEFAULT_TIMEZONE = 'UTC+3';

function svgLoaderFactory(
  http: HttpClient,
  transferState: TransferState,
): SvgBrowserLoader {
  return new SvgBrowserLoader(http, transferState);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      EventPluginsModule,
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
      AngularYandexMapsModule.forRoot(yaMapConfig$),
      FlexLayoutModule.withConfig(
        flexLayoutConfigOptions,
        flexLayoutBreakpoints,
      ),
      GalleryModule,
    ),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'disabled' }),
    ),
    provideAnimationsAsync(),
    provideClientHydration(),
    provideHttpClient(withInterceptors([HttpHeadersInterceptor])),
    provideAngularSvgIcon({
      loader: {
        deps: [HttpClient, TransferState],
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
      },
    }),
    provideTransloco({
      config: <PartialTranslocoConfig>{
        availableLangs: ['ru', 'en'],
        defaultLang: 'ru',
        flatten: {
          aot: !isDevMode(),
        },
        prodMode: !isDevMode(),
        reRenderOnLangChange: true,
      },
      loader: TranslocoHttpLoader,
    }),
    provideStore(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: <RuntimeChecks>{
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      },
    ),
    provideState(cartFeature),
    provideEffects([CartEffects]),
    provideRouterStore(),
    isDevMode()
      ? provideStoreDevtools(<StoreDevtoolsOptions>{
          connectInZone: true,
          logOnly: !isDevMode(),
          maxAge: 25,
          name: 'PIREXPO NGRX Store',
        })
      : [],
    {
      provide: ApiConfiguration,
      useValue: { rootUrl: `/api/v${environment.API_VERSION}` },
    },
    {
      provide: ErrorHandler,
      useValue: SentryBrowser.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      deps: [Router],
      provide: SentryBrowser.TraceService,
    },
    {
      deps: [SentryBrowser.TraceService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: DEFAULT_LOCALE },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: <DatePipeConfig>{ timezone: DEFAULT_TIMEZONE },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: <MatSnackBarConfig>{ duration: 5000 },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{ appearance: 'outline' },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: <MatCheckboxDefaultOptions>{ clickAction: 'check' },
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: <RippleGlobalOptions>{ disabled: true },
    },
  ],
};
