import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Cart } from '@swagger/models';

export const CartActions = createActionGroup({
  events: {
    'Add Cart Item': props<{ ticketTypeId: number }>(),
    'Add Cart Item Failure': props<{ error: unknown }>(),
    'Add Cart Item Success': props<{ cart: Cart }>(),

    'Apply Promocode': props<{ promocode: string }>(),
    'Apply Promocode Failure': props<{ error: unknown }>(),
    'Apply Promocode Success': props<{ cart: Cart }>(),

    'Create Cart Order': emptyProps(),
    'Create Cart Order Failure': props<{ error: unknown }>(),
    'Create Cart Order Success': emptyProps(),

    'Load Cart': emptyProps(),
    'Load Cart Failure': props<{ error: unknown }>(),
    'Load Cart Success': props<{ cart: Cart }>(),

    'Release Promocode': emptyProps(),
    'Release Promocode Failure': props<{ error: unknown }>(),
    'Release Promocode Success': props<{ cart: Cart }>(),

    'Remove Cart Item': props<{ itemId: string }>(),
    'Remove Cart Item Failure': props<{ error: unknown }>(),
    'Remove Cart Item Success': props<{ cart: Cart }>(),

    'Update Cart Item': props<{
      itemId: string;
      quantity: number;
      ticketTypeId: number;
    }>(),
    'Update Cart Item Failure': props<{ error: unknown }>(),
    'Update Cart Item Success': props<{ cart: Cart }>(),
  },
  source: 'Cart',
});
